@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --default-cell-bg-color: theme('colors.white');
  --correct-cell-bg-color: theme('colors.green.200');
  --revealing-cell-color: theme('colors.white');
  --revealed-cell-color: theme('colors.blue.500');
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --correct-cell-bg-color: theme('colors.green.900');
  --revealing-cell-color: theme('colors.slate.900');
  --revealed-cell-color: theme('colors.blue.400');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* remove outline for non-tabbing users */
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

.app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.height-100vh {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.min-height-100vh {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

.height-100vh-with-padding {
  height: calc(100vh - 8px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 8px);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: 250ms;
}

.cell-reveal {
  animation-name: revealCorrectCharCell;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.cell-revealed {
  background-color: var(--correct-cell-bg-color) !important;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.4s linear;
  animation-fill-mode: forwards;
}

.letter-reveal {
  animation-name: revealLetterCell;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.letter-revealed {
  color: var(--revealed-cell-color) !important;
}

.letter-reveal > .letter-container {
  animation: offsetLetterFlip 0.4s linear;
  animation-fill-mode: forwards;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
  }
  100% {
    background-color: var(--correct-cell-bg-color);
    transform: rotateX(180deg);
  }
}

@keyframes revealLetterCell {
  0% {
    transform: rotateX(0deg);
    color: var(--revealing-cell-color);
  }
  50% {
    color: var(--revealing-cell-color);
  }
  50.1% {
    color: var(--revealed-cell-color);
  }
  100% {
    color: var(--revealed-cell-color);
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}
